/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/*table.google-visualization-orgchart-node {
    !*background: blue;*!
    border-collapse: separate !important;
}*/


/*table.google-visualization-orgchart-node-medium {
    !*background: blue;*!
    border-collapse: separate !important;
}*/


/* .ubuntuBold{
    font-family: 'ubuntu';
    font-weight: bold;
   }

 .ubuntuRegular{
 font-family: 'ubuntu';

} */


/*.cdk-overlay-container {
    z-index: 100001 !important;
}*/


/*.work-in-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    !*-webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);*!
}*/


/*/deep/ .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #6b60e6;
}

/deep/ .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #6b60e6;
}*/

::ng-deep .mat-checkbox .mat-checkbox-frame {
    border-color: #6b60e6;
}


/* for mat tab group */

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #6b60e6;
    /* height: 5px;  */
}

table.google-visualization-orgchart-table {
    /*background: blue !important;*/
    border-collapse: separate !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #6b60e6 !important;
}


/* table.google-visualization-orgchart-linebottom {
    border-bottom: 4px solid #ff7f11!important;
}

table.google-visualization-orgchart-lineleft {
    border-left: 4px solid #ff7f11 !important;
}

table.google-visualization-orgchart-lineright {
    border-right: 4px solid #ff7f11!important;
}

table.google-visualization-orgchart-linetop {
    border-top: 4px solid #ff7f11 !important;
} */

.myNodeClass {
    /*border-collapse: separate !important;*/
    font-size: 14px !important;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    font-family: arial, helvetica, serif;
    cursor: default;
    border: 1px solid #f7f7f7;
    -webkit-column-width: 500px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
    background: #fcfcfc !important;
}

.custom-modalbox>mat-dialog-container {
    /*padding: 0 !important;*/
    overflow: visible;
    /*background: none;*/
}


/* checkbox color */

input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 16px;
    width: 16px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #504f4f !important;
    color: #FF9F10 !important;
    font-weight: 500;
}

.mat-focused .mat-form-field-label {
    /*change color of label*/
    color: #504f4f !important;
    font-weight: 500;
}

.mat-option:hover {
    background-color: #504f4f !important;
    color: #FF9F10 !important;
    font-weight: 500;
}

.mat-option::selection {
    background-color: #504f4f !important;
    color: #FF9F10 !important;
    font-weight: 500;
}

.mat-option.mat-selected.mat-active {
    background: #504f4f !important;
    color: #FF9F10 !important;
    font-weight: 500;
}

.mat-selected {
    background: #504f4f !important;
    color: #FF9F10 !important;
    font-weight: 500;
}


/*.mat-slider.mat-slider-horizontal .mat-slider-thumb .mat-slider-thumb-label {
    opacity: 1 !important;
    visibility: visible !important;
    background-color: #FF9F10 !important;
}*/


/*::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: blue; !*replace with your color*!
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: skyblue;  !*replace with your color*!
}*/


/*.mat-form-field-underline {
    background-color: #FF7F11 !important;
}*/


/* scrolling color  */


/* width */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #AAAA;
    border-radius: 10px;
}


/*::ng-deep datepicker-value-example .mat-form-field-label{
    color:#FF9F10;
}*/


/*::ng-deep .mat-datepicker-toggle{
    color:#FF9F10 !important;
}*/


/* ::ng-deep .mat-form-field-underline{
    background: #FF9F10 !important;
} */

input[type="checkbox"]:checked {
    /* background: #2aa1c0;  */
    background: #6b60e6;
    /*box-shadow: 0 10px 20px 0 ,0 17px 50px 0 rgba(0,0,0,0.19);*/
}

input[type="checkbox"]:after {
    content: '';
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    display: none;
}

input[type="checkbox"]:checked:after {
    display: block;
}


/*.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: #9c27b0 !important;
    color: #9c27b0 !important;
}

.ng-star-inserted {display: none}*/


/*.mySelectedNodeClass {
    border: 2px solid #e38493;
    background: green;
}*/


/*.testglobal {*/


/*background-color: red;*/


/*text-align: center;*/


/*border: 67px royalblue;*/


/*}*/


/* ::ng-deep .mat-select-content{
    width:2000px;
    background-color: red;
    font-size: 10px;
} */


/*
mat-option {
      background-color: #ffffff;
       color: rgb(0, 0, 0);
    } */


/* .btn-warning{
    background-color: red;
} */

.btn-orangetheme {
    background-color: #6b60e6 !important;
    background-repeat: repeat-x;
    background-image: -o-linear-gradient(top, #6b60e6, #6b60e6);
    background-image: linear-gradient(#6b60e6, #6b60e6);
    border-color: #6b60e6 #6b60e6 #6b60e6;
    color: #fff !important;
    text-shadow: 0 10px 0 #6b60e6;
    font-family: "Ubuntu Mono", monospace;
}


/*.cdk-header-cell {
    text-align: center;
}*/


/*.mat-row:nth-child(4n+1){
    background-color: #f2f2f2;
}
.mat-row:hover{
    background-color: #f2f2f2;
    !* cursor: all-scroll;  *!
}
.mat-row:nth-child(1n+1):hover{
    background-color: #f2f2f2;
    !* cursor: all-scroll;  *!
}

td.mat-cell {
    border: none;
}*/


/*.mat-chip-selected {*/


/*color: #FF9F10;*/


/*}*/


/*.mat-header-row, .mat-row {
    height: 50px;
}*/


/*td {
    text-align: center;
}

th {
    text-align: center;
}*/

.mat-input-element {
    color: black !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #6b60e6 !important;
}

.mat-accent .mat-slider-thumb {
    background-color: #6b60e6 !important;
    transform: scale(0.4) !important;
}

.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
    background-color: #6b60e6 !important;
}


/*.mat-slider-thumb {
    transform: scale(0) !important;
}*/

.mat-slider-thumb-label-text {
    opacity: 1 !important;
}


/*.mat-slider-thumb-label.mat-checked:not(.mat-disabled) {

}*/


/*.mat-option:hover {
    color: rgba(0, 139, 139, 0.7);
}*/

.copyright.pull-center {
    font-family: "Ubuntu Mono", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

th.mat-header-cell {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
}

td,
p,
a,
label {
    font-family: "Ubuntu Mono", monospace;
}

.mat-tab-label .mat-tab-label-content {
    font-family: "Ubuntu Mono", monospace;
}

a.mat-tab-link {
    font-family: "Ubuntu Mono", monospace;
}

.mat-form-field.mat-focused .mat-form-field-label {
    font-family: "Open Sans", sans-serif;
}

.mat-form-field .mat-input-element {
    font-family: "Ubuntu Mono", monospace;
}

.mat-select-value {
    font-family: "Ubuntu Mono", monospace;
    color: black !important;
}

.mat-option-text {
    font-family: "Ubuntu Mono", monospace;
}

::ng-deep .mat-input-placeholder {
    font-family: "Ubuntu Mono", monospace;
}

.mat-step-label {
    font-family: "Ubuntu Mono", monospace;
}

.mat-checkbox-label {
    font-family: "Ubuntu Mono", monospace;
}

input .mat-form-field-placeholder {
    font-family: "Ubuntu Mono", monospace;
}

.mat-form-field-wrapper {
    font-family: "Ubuntu Mono", monospace;
}

.mat-select-content {
    font-family: "Ubuntu Mono", monospace;
}

.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margins {
    color: #6b60e6;
}

mat-checkbox {
    color: #6b60e6;
}


/*deep*/

.mat-checkbox-background {
    background-color: #6b60e6;
}


/*deep*/

.mat-checkbox-focused .mat-ink-ripple {
    background-color: #6b60e6;
}

.swal-button {
    background-color: #6b60e6 !important;
    background-repeat: repeat-x;
    background-image: -o-linear-gradient(top, #6b60e6, #6b60e6);
    background-image: linear-gradient(#6b60e6, #6b60e6);
    border-color: #6b60e6 #6b60e6 #6b60e6;
    color: #fff !important;
    text-shadow: 0 10px 0 #6b60e6;
    font-family: "Ubuntu Mono", monospace !important;
    width: 100px;
    /*height: 40px;*/
    font-size: 18px !important;
}

.swal-custom-class {
    font-size: 20px !important;
    font-family: "Ubuntu Mono", monospace !important;
    color: #FF9F10 !important;
    /*z-index: 100011 !important;*/
    /* --success: #FF9F10 !important;*/
    /*width: 30% !important;
    height: 40% !important;*/
}


/*.swal2-success-ring {
    color: #FF9F10 !important;
}*/


/*.swal2-icon .swal2-success .swal2-animate-success-icon {
    color: #FF9F10 !important;
}*/

.cad {
    /* background-color: rgb(248, 242, 231); */
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    /* border-left: 5px solid #EF8D00; */
    border-left: 2px solid #EBEEF3;
    border-right: 2px solid #EBEEF3;
    border-top: 2px solid #EBEEF3;
    border-bottom: 2px solid #EBEEF3;
}

.formcard {
    background-color: #FFFFFF;
    /* border-left: 5px solid #EF8D00; */
    border-left: 2px solid #EBEDF3;
    border-right: 2px solid #EBEDF3;
    border-top: 2px solid #EBEDF3;
    border-bottom: 2px solid #EBEDF3;
    -webkit-box-shadow: 0px 13px 16px 5px rgba(220, 229, 242, 1);
    -moz-box-shadow: 0px 13px 16px 5px rgba(220, 229, 242, 1);
    box-shadow: 0px 13px 16px 5px rgba(220, 229, 242, 1);
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 20px;
}

.center {
    /* margin: auto;
    width: 30%; */
    width: 100%;
    margin-top: 15px;
    max-width: 300px;
    /* Original width of the logo image */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.center1 {
    text-align: center;
    margin-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Ubuntu-Medium', 'Roboto', 'sans-serif';
}

.pagetitle {
    font-size: large;
    font-weight: bold;
}

.verticaldiv {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    /* Original width of the logo image */
    margin-left: 15%;
    margin-right: auto;
    text-align: center;
}

.res {
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.dropdown {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

#cancel {
    color: red;
}

#check {
    color: green;
}

#edit {
    color: orange;
}

.reportbtn {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: table;
    font-size: 50px;
    margin-bottom: 20px;
}

.hide {
    visibility: hidden;
}

.headingClass {
    background-color: #31302e;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 15px;
    /* font-weight: 300; */
}

.errorFont {
    font-size: 15px;
    color: rgb(250, 154, 154);
    transform: translate(0px, -15px);
}

.example-container .example-is-mobile {
    background-color: rgb(121, 20, 20) !important;
}